export const SidebarGreen = "#0A0C37FF"

export const SidebarGreenHover = "#55FFBB"

export const Purple = "#0A0C37FF"
export const Pink = "#FF66C1FF"

export const Orange = "#FF9900FF"
export const LightBlue = "#5CECFFFF"

export const Yellow = "#F4FF61FF"

export const IronGrey = "#375971FF"
