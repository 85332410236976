import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faLinkedin,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons"

import { rhythm } from "../utils/typography"
import {
  NavigationList,
  NavigationLink,
  NavigationLinkIcon,
  NavigationLinkText,
} from "./Merry/Navigation"
import { Button } from "./Merry/Buttons"
import { IronGrey, LightBlue, Pink, SidebarGreenHover } from "./Merry/colors"
import { faHome, faCube } from "@fortawesome/free-solid-svg-icons"

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rhythm(2.5)};
  text-align: center;
`

const Header = styled.h1`
  margin-top: 1.75rem;

  @media screen and (min-height: 700px) {
    margin-top: 3.5rem;
  }
`

const Section = styled.div`
  padding: 0 0 16px 0;
`

const SocialMediaLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SocialMediaLink = styled.a`
  width: 48px;
  height: 48px;
  padding-top: 8px;
  box-shadow: none;
  color: ${IronGrey};
  border-radius: 5px;
  transition: all 0.25s linear;

  &:hover {
    background-color: ${Pink};
  }
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 120, height: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          title
          author {
            name
            summary
          }
          social {
            github
            linkedin
            stackoverflow
          }
        }
      }
    }
  `)

  const { title, author, social } = data.site.siteMetadata
  return (
    <Root>
      <Header>{title}</Header>
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          minWidth: 128,
          minHeight: 128,
          borderRadius: `100%`,
          margin: "0 auto",
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <Section style={{ marginTop: "1.75rem", padding: 0 }}>
        <p>
          Written by <strong>{author.name}</strong> {author.summary}
        </p>
      </Section>
      <Section>
        <SocialMediaLinks>
          <SocialMediaLink
            href={`https://github.com/${social.github}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faGithub} size="2x" />
          </SocialMediaLink>
          {/*<SocialMediaLink*/}
          {/*  href={`https://uk.linkedin.com/in/${social.linkedin}`}*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={faLinkedin} size="2x" />*/}
          {/*</SocialMediaLink>*/}
          {/*<SocialMediaLink*/}
          {/*  href={`https://stackoverflow.com/users/${social.stackoverflow}`}*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={faStackOverflow} size="2x" />*/}
          {/*</SocialMediaLink>*/}
        </SocialMediaLinks>
      </Section>
      <Section>
        <NavigationList>
          <NavigationLink to={`/`}>
            <NavigationLinkIcon>
              <FontAwesomeIcon icon={faHome} size="lg" />
            </NavigationLinkIcon>
            <NavigationLinkText>Home</NavigationLinkText>
          </NavigationLink>
          <NavigationLink to={`/projects`}>
            <NavigationLinkIcon>
              <FontAwesomeIcon icon={faCube} size="lg" />
            </NavigationLinkIcon>
            <NavigationLinkText>Projects</NavigationLinkText>
          </NavigationLink>
        </NavigationList>
      </Section>
      <NavigationLink to={`/about`} style={{ boxShadow: "none", padding: 0 }}>
        <Button style={{ backgroundColor: "transparent", color: LightBlue }}>
          Get In Touch
        </Button>
      </NavigationLink>
    </Root>
  )
}

export default Bio
