import { Link } from "gatsby"
import styled from "styled-components"
import { IronGrey, Pink, Purple, SidebarGreenHover } from "./colors"

export const NavigationList = styled.div`
  display: flex;
  flex-direction: column;
`

export const NavigationLink = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  width: 100%;
  padding: 8px;
  margin: 4px 0;
  border-radius: 5px;
  transition: all 0.25s linear;
  text-align: left;
  color: ${Pink};

  &:hover {
    background-color: ${Pink};
    color: ${Purple};
  }

  &:visited {
    text-decoration: none;
    color: ${Pink};

    &:hover {
      background-color: ${Pink};
      color: ${Purple};
    }
  }

  &[aria-current="page"] {
    background-color: ${Pink};
    color: ${Purple};

    &:hover {
      background-color: ${Pink};
      color: ${Purple};
    }
  }
`

export const NavigationLinkIcon = styled.span`
  padding: 0 16px;
`

export const NavigationLinkText = styled.span``
