import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { Swipeable, EventData } from 'react-swipeable'

import SEO from './seo'
import Layout from './Merry/Project/Layout'
import Sidebar from './Merry/Sidebar'
import Bio from './bio'
import PageUpButton from './Merry/Project/PageUpButton'
import Footer from './Merry/Footer'

interface Props {
    children?: any
    title: string
    description: string
    navOpen: boolean
}

const PageWrap = ({ title, description, children, navOpen }: Props) => {
    const [visibleState, setVisibleState] = useState(navOpen);
  
    const toggleSidebarVisibility = () => setVisibleState(!visibleState);
  
    return (
      <Swipeable
        onSwipedLeft={(data: EventData) => setVisibleState(true)}
        onSwipedRight={(data: EventData) => setVisibleState(false)}
      >
        <SEO
          title={title}
          description={description}
        />
        <Layout>
          <Sidebar collapsable={true} isOpen={visibleState} toggleVisibility={toggleSidebarVisibility}>
            <Bio />
          </Sidebar>
          { children }
        </Layout>
        <PageUpButton
          topTarget="#contentRoot"
          scrollTarget="#pageRoot"
          targetFooter="#footer"
        />
        <Footer/>
      </Swipeable>
    )
  }

export default PageWrap
