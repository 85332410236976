import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { rhythm, SizeMD } from "./vars"
import { IronGrey, Pink, Purple, SidebarGreen } from "./colors"

interface SlideoutButtonProps {
  collapsable: boolean
}

const SlideoutButton = styled.div`
  position: absolute;
  right: -48px;
  height: 48px;
  width: 48px;
  z-index: 9;
  cursor: pointer;
  padding: 8px 10px;
  background-color: ${Purple};
  color: ${IronGrey};
  border-bottom-right-radius: 4px;

  @media screen and (min-width: ${SizeMD}) {
    display: none;
  }
`

interface WrapperProps {
  collapse?: boolean
  collapsable: boolean
}

const Wrapper = styled.div`
  top: 0;
  left: ${(props: WrapperProps) => (props.collapse ? "-292px" : 0)};
  bottom: 0;
  width: 300px;
  min-width: 300px;
  z-index: 50;
  display: flex;
  transition: left 0.4s ease-in-out;
  position: fixed;

  @media screen and (min-width: ${SizeMD}) {
    position: static;
    left: ${(props: WrapperProps) =>
      props.collapse && props.collapsable ? "-292px" : 0};
  }
`

interface WrapperInteriorProps {
  collapse?: boolean
}
const WrapperInterior = styled.div`
  background-color: ${SidebarGreen};
  color: #fff;
  padding: ${rhythm(1.5)};
  width: 100%;
  border-right: 3px solid ${Pink};

  @media screen and (min-width: ${SizeMD}) {
    top: 0;
    left: ${(props: WrapperInteriorProps) => (props.collapse ? "-292px" : 0)};
    bottom: 0;
    width: 300px;
    position: fixed;
  }
`

interface Props {
  children?: any
  collapsable?: any
  isOpen: boolean
  toggleVisibility: () => void
}

const Sidebar = ({
  children,
  collapsable,
  isOpen,
  toggleVisibility,
}: Props) => (
  <Wrapper collapse={!isOpen} collapsable={collapsable}>
    <SlideoutButton onClick={toggleVisibility} collapsable={collapsable}>
      <FontAwesomeIcon icon={faBars} size="2x" />
    </SlideoutButton>
    <WrapperInterior>{children}</WrapperInterior>
  </Wrapper>
)

export default Sidebar
