import React from 'react'
import styled from "styled-components"


const FooterWrapper = styled.footer`
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface Props {
    children?: any
}

const Footer = ({ children }: Props) => (
    <>
        <FooterWrapper id="footer">
            { children }
        </FooterWrapper>
    </>
)

export default Footer
