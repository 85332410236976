import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { Pink, SidebarGreen } from "./../colors"

const ButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 64px;
  text-align: right;
  height: 64px;

  @media (max-width: 768px) {
    padding: 32px;
    height: 128px;
  }
`

interface ButtonRootProps {
  isSticky: boolean
  lockPosition: boolean
}

const ButtonRoot = styled.div`
  position: fixed;
  bottom: ${(props: ButtonRootProps) =>
    props.lockPosition ? "64px" : "-128px"};
  opacity: ${(props: ButtonRootProps) => (props.lockPosition ? "1" : "0")};
  right: 64px;
  transition: bottom ease-in 0.35s, opacity ease-out 0.35s;
  z-index: 10;

  @media (max-width: 768px) {
    bottom: ${(props: ButtonRootProps) =>
      props.lockPosition ? "32px" : "-128px"};
    right: 32px;
  }

  font-family: libre-franklin, arial, serif;
`

const ButtonBase = styled.button`
  background-color: ${SidebarGreen};
  height: 50px;
  width: 160px;
  border-radius: 35px;
  cursor: pointer;
  border: 0;
  transition: 0.25s ease;

  &:focus {
    outline: 0;
  }

  &:hover {
    transform: scale(1.1);
  }
`

const Text = styled.div`
  text-transform: uppercase;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  text-align: center;
  font-size: 16px;
  padding: 4px 12px;
  color: ${Pink};
`

const IconContainer = styled.span`
  margin: 0 0 0 8px;
`

interface PageUpButtonProps {
  topTarget: string
  scrollTarget: string
  targetFooter: string
}

const PageUpButton = ({
  topTarget,
  scrollTarget,
  targetFooter,
}: PageUpButtonProps) => {
  const [isVisibleState, setIsVisibleState] = useState(false)
  const [isStickyState, setIsStickyState] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset
      setScrollPosition(position)
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setIsStickyState(false)
        } else {
          setIsStickyState(true)
        }
      },
      { threshold: [0] }
    )

    const el = document.querySelector(targetFooter)
    if (el !== null) {
      observer.observe(el)
    }

    return () => {
      observer.disconnect()
      window.removeEventListener("scroll", handleScroll)
    }
    // eslint-disable-next-line
  }, [topTarget, targetFooter])

  useEffect(() => {
    const el = document.querySelector(topTarget) as HTMLElement
    if (el === null) {
      return
    }

    if (el.offsetTop <= scrollPosition) {
      setIsVisibleState(true)
    } else {
      setIsVisibleState(false)
    }
  }, [scrollPosition, topTarget])

  return (
    <ButtonContainer>
      <ButtonRoot lockPosition={isVisibleState} isSticky={isStickyState}>
        <AnchorLink href={scrollTarget} style={{ boxShadow: "none" }}>
          <ButtonBase>
            <Text>
              Page Up
              <IconContainer>
                <FontAwesomeIcon icon={faCaretUp} size="lg" />
              </IconContainer>
            </Text>
          </ButtonBase>
        </AnchorLink>
      </ButtonRoot>
    </ButtonContainer>
  )
}

PageUpButton.propTypes = {
  topTarget: PropTypes.string.isRequired,
  scrollTarget: PropTypes.string.isRequired,
  targetFooter: PropTypes.string.isRequired,
}

export default PageUpButton
