import styled from "styled-components"
import { IronGrey, Pink, SidebarGreen, SidebarGreenHover } from "./colors"

export const Button = styled.div`
  background-color: ${IronGrey};
  transition: all 0.3s linear;
  text-align: center;
  color: #000;
  padding: 8px;
  border-radius: 5px;

  &:hover {
    background-color: ${Pink};
  }
`
