import React from 'react'
import styled from 'styled-components'
import { SizeMD } from '../vars'

interface RootProps {
    collapsable: boolean
}

const Root = styled.main`
    width: 100%;
    ${({ collapsable }: RootProps) => !collapsable? `
        display: flex;
        flex-direction: row;
        min-height: 100vh;
    ` : ''}

@media screen and (min-width: ${SizeMD}) {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}
`

interface BodyProps {
    children?: any
    collapsable?: boolean
}

const Body = ({ children, collapsable }: BodyProps) => {
    return (
        <Root id="pageRoot" collapsable={collapsable !== undefined? collapsable : true}>
            { children }
        </Root>
    )
}

export default Body
